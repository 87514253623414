import React, { useState, useEffect } from "react";
import nft1 from "../Assets/Images/001.mp4";
import nft2 from "../Assets/Images/002.mp4";
import nft3 from "../Assets/Images/003.mp4";
import nft4 from "../Assets/Images/004.mp4";
import nft5 from "../Assets/Images/005.mp4";
import nft6 from "../Assets/Images/006.mp4";
import nft7 from "../Assets/Images/007.mp4";
import nft8 from "../Assets/Images/008.mp4";
import nft50 from "../Assets/Images/Comp new.mp4"
import eye from "../Assets/Images/eye-solid.svg";
import logo1 from "../Assets/Images/20001.png";
import logo2 from "../Assets/Images/czx.png";
import copy from "../Assets/Images/icons8-copy-64.png";
import Copy from "copy-to-clipboard";
import ReactPlayer from "react-player";
import toast from "react-hot-toast";

import { useGlobalContext } from "../context/walletContext";
import NFTFiles from "../Assets/data";

const Nft = () => {
  const {
    checkAllowance,
    approveToken,
    address,
    mintNFT,
    tokenValue,
    network,
    approvePair,
    mintWithPair,
  } = useGlobalContext();
    
  const [tokenApprovalFlag, setTokenApprovalFlag] = useState([false,false,false]);  // VTC,CZX,USDT

  // VTC || CZX approval..

const handleNFTBuy = async (token, nftNo) => {

  const index = nftNo - 1;
  let updatedFlags = [...tokenApprovalFlag];

  if (token === "VTC") {

    if (!updatedFlags[0]) {
      const isApproved = await approveToken(NFTFiles.VTC);
      if (isApproved) {
        updatedFlags[0] = true;
        setTokenApprovalFlag(updatedFlags);
      }
    } else {
      await mintNFT(NFTFiles.VTC, nftNo);
    }
  } else if(token === "CUSD") {
    if (!updatedFlags[index][1]) {
      const isApproved = await approveToken(NFTFiles.CZX);
      if (isApproved) {
        updatedFlags[1] = true;
        setTokenApprovalFlag(updatedFlags);
      }
    } else {
      await mintNFT(NFTFiles.CZX, nftNo);
    }
  }else{

    if (!updatedFlags[2]) {
      const isApproved = await approveToken(NFTFiles.USDT, nftNo);
      if (isApproved) {
        updatedFlags[2] = true;
        setTokenApprovalFlag(updatedFlags);
      }
    } else {
      await mintNFT(NFTFiles.USDT, nftNo);
    }


  }
};

const handleBuyPairNFT = async(pairNo,nftNo)=>{

  if(pairNo === 1){

    if(tokenApprovalFlag[1] && tokenApprovalFlag[2]){
      await mintWithPair(pairNo,nftNo);
    }else{
      await approvePair(NFTFiles.USDT,NFTFiles.CZX)
    }
   
  }else if(pairNo === 2){

    
    if (tokenApprovalFlag[1] && tokenApprovalFlag[0]) {
      await mintWithPair(pairNo, nftNo);
    } else {
      await approvePair(NFTFiles.VTC, NFTFiles.CZX);
    }

  }
     
}

const handleAddressShow = (nftAddress)=>{
   let str = nftAddress.slice(0, 4) + "..." + nftAddress.slice(-4);
   return str;
}

const handleCopyAddress = (nftAddress)=>{
  Copy(nftAddress);
  toast.success("Address Copied");
}


useEffect(() => {

  const checkAndUpdateApprovalFlags = async () => {

      const vtcA = await checkAllowance(NFTFiles.VTC);
      const czxA = await checkAllowance(NFTFiles.CZX);
      const usdtA = await checkAllowance(NFTFiles.USDT);
      setTokenApprovalFlag([vtcA, czxA, usdtA]);
  };

  if (address && network == NFTFiles.NETWORK_ID) {
    checkAndUpdateApprovalFlags();
  }
}, [address,network]);

  return (
    <>
      <section className="nft mb-5">
        <div className="container">
          <h2 className="nft-headline mt-5">
            VitnixX <span>NFT</span>{" "}
          </h2>
          <div className=" mx-auto d-flex justify-content-center  w-fit mt-4 mb-5 ">
            <button
              type="button"
              className="md:d-flex pricebtn items-center rounded bg-transparent border    py-2  px-3 mx-2 rounded-2xl"
            >
              <img
                className="w-6 mr-2"
                src={logo1}
                width={24}
                height={24}
                alt="logo-gif"
              />
              <span className=" text-white">VTC Price : ${tokenValue[0]}</span>
            </button>
            <button
              type="button"
              className="md:d-flex pricebtn items-center rounded bg-transparent border    py-2 px-3 mx-2 rounded-2xl"
            >
              {/* <img
                className="w-6 mr-0"
                src={logo2}
                width={24}
                height={24}
                alt="logo-gif"
              /> */}
              <span className=" text-white">CUSD Price : ${tokenValue[1]}</span>
            </button>
          </div>

          <div className="row">
          <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft50}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[8])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[8])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[8].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[8].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$50</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 9)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 9)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 9)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 9)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft1}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[0])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[0])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[0].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[0].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$100</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 1)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 1)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 1)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 1)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft2}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[1])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[1])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                              {/* <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 448 512"
                                className="w-3"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                              </svg> */}
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[1].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[1].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$250</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 2)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 2)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 2)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 2)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft3}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[2])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[2])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                              {/* <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 448 512"
                                className="w-3"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                              </svg> */}
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[2].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[2].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$1000</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 3)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 3)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 3)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 3)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft4}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[3])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[3])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                              {/* <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 448 512"
                                className="w-3"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                              </svg> */}
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[3].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[3].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$2500</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 4)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 4)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 4)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 4)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft5}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[4])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[4])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                              {/* <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 448 512"
                                className="w-3"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                              </svg> */}
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[4].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[4].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$5000</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 5)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 5)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 5)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 5)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft6}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[5])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[5])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                              {/* <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 448 512"
                                className="w-3"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                              </svg> */}
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[5].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[5].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$10,000</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 6)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 6)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 6)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 6)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft7}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[6])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[6])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                              {/* <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 448 512"
                                className="w-3"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                              </svg> */}
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[6].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[6].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$20,000</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 7)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 7)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 7)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 7)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 col-xl-3 ">
              <div className="padding-box">
                <div className="element-container">
                  <div className="inner-box">
                    <div className="boder bd1">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd2">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd3">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="boder bd4">
                      <i className="icon icon-stroke"></i>
                    </div>
                    <div className="wrap-content">
                      <div className="pxl-item-image">
                        <ReactPlayer
                          className="react-player"
                          url={nft8}
                          width="240px"
                          height="auto"
                          muted
                          loop
                          playing="true"
                          playsinline="true"
                        />
                      </div>
                      <div className=" pxl-space d-flex flex-column gap-y-1 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">Address</span>
                          <span className="d-flex align-items-center gap-x-2">
                            {handleAddressShow(NFTFiles.NFT_CONTRACTS[7])}
                            <span
                              onClick={() =>
                                handleCopyAddress(NFTFiles.NFT_CONTRACTS[7])
                              }
                            >
                              <img src={copy} className="copyimg" alt="" />
                              {/* <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 448 512"
                                className="w-3"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"></path>
                              </svg> */}
                            </span>{" "}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">VTC Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[7].value / tokenValue[0]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">CUSD Value</span>
                          <span className="d-flex">
                            {tokenValue
                              ? (NFTFiles.nft[7].value / tokenValue[1]).toFixed(
                                  2
                                )
                              : "--"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-between align-items-center text-lg">
                          <span className="d-flex">NFT Value</span>
                          <span className="d-flex">$50,000</span>
                        </div>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("VTC", 8)}
                        >
                          {tokenApprovalFlag[0]
                            ? "Buy with VTC"
                            : "Approve VTC"}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(1, 8)}
                        >
                          {tokenApprovalFlag[1] && tokenApprovalFlag[2]
                            ? "Buy with USDT & CUSD"
                            : "Approve USDT & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleBuyPairNFT(2, 8)}
                        >
                          {tokenApprovalFlag[0] && tokenApprovalFlag[1]
                            ? "Buy with VTC & CUSD"
                            : "Approve VTC & CUSD"}
                        </button>

                        <button
                          type="submit"
                          class="btn btn-primary w-full mt-3 mb-auto"
                          onClick={() => handleNFTBuy("USDT", 8)}
                        >
                          {tokenApprovalFlag[2]
                            ? "Buy with USDT"
                            : "Approve USDT"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Nft;
