import React from "react";
import bannerimage2 from "../Assets/Images/v3.gif";
import NFTFiles from "../Assets/data";
import { useGlobalContext } from "../context/walletContext";
import toast from "react-hot-toast";

const Banner = () => {


  const {addTokenToMetaMask} = useGlobalContext();

  const handleAddToken = async (tokenStr) => {

    if(tokenStr === "VTC"){
       await addTokenToMetaMask(NFTFiles.VTC,"VTC");
    }else{
      await addTokenToMetaMask(NFTFiles.CZX,"CZX");
    }


  }


  return (
    <>
      <section className="banner-area" id="Home">
        <div className="container">
          <div className="row align-items-center gap-3 gap-lg-0">
            <div className="col-12 col-lg-6">
              <div className="info-group" data-aos="fade-right">
                <h1
                  className="pxl-item--title  highlight-default"
                  data-wow-delay="ms"
                >
                  VitnixX <span> NFT </span>Marketplace
                </h1>
                <p className="">
                  Welcome to the VitnixX NFT Marketplace, a vibrant and
                  decentralized hub for digital collectors, and enthusiasts. Our
                  NFT marketplace revolutionizes the way you discover, and trade
                  unique digital collectibles.
                </p>
               <div className=" d-flex flex-sm-row flex-column align-items-sm-start align-items-center ">
                <div className="banner-btn-grp mt-0">
                  <a href="#" className="btn-main">
                    <span onClick={() => handleAddToken("VTC")}>Add VTC</span>
                  </a>
                  <a href="#" className="btn-main">
                    <span onClick={() => handleAddToken("CZX")}>Add CUSD</span>
                  </a>
                  </div>
                  <div className="banner-btn-grp mt-sm-0 mt-1   ">
                  <a href="https://swap.vitnixx.com/" className="btn-main">
                    <span>Buy VTC</span>
                  </a>
                  <a href="https://swap.vitnixx.com/" className="btn-main">
                    <span>Buy CUSD</span>
                  </a>
                  
                </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="banner-img-group">
                <img
                  className="color-changing-element"
                  src={bannerimage2}
                  height={640}
                  width={640}
                  alt="banner-logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
