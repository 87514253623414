import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/Assets/Css/Style.css";
import App from "./App";
import WalletContextProvider from "./context/walletContext.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WalletContextProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </WalletContextProvider>
);
