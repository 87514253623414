const NFTFiles = {
  nft: [
    {
      name: "GALACTIC PIONEER",
      value: 100,
      link: "https://nftstorage.link/ipfs/bafkreigzswr7czgmo4y5bh7aoa26iyxjzvdhnkkar5a4lrmqpwjv3aqvxm",
    },
    {
      name: "CELESTIAL VOYAGER",
      value: 250,
      link: "https://nftstorage.link/ipfs/bafkreicv77soh56wnqll7c2p42mojldmldbwltgbhzkdxmbk36zgjd727e",
    },
    {
      name: "STELLAR COMMANDER",
      value: 1000,
      link: "https://nftstorage.link/ipfs/bafkreihbr5eiap5skm7jbjtxanrpgbcgdsx3mkwzu4nr4cwnwovlauuziu",
    },
    {
      name: "COSMIC EXPLORER",
      value: 2500,
      link: "https://nftstorage.link/ipfs/bafkreid3eloymicirywucfm7uacxnrbseneqesug4wzexea4slz5kii2ya",
    },
    {
      name: "ASTRO TRAILBLAZER",
      value: 5000,
      link: "https://nftstorage.link/ipfs/bafkreicqhpmfst7wtcgqo26xkewi5voqld5qhyac4lz6wgtlcs64cgoo3m",
    },
    {
      name: "NEBULA NAVIGATOR",
      value: 10000,
      link: "https://nftstorage.link/ipfs/bafkreih3kaem7c4woxssalfbdsi3l4h4jr57xyj4b74rijx6iuvvksheam",
    },
    {
      name: "SPACE ODYSSEY CAPTAIN",
      value: 20000,
      link: "https://nftstorage.link/ipfs/bafkreia4wjsepiozf7qbu4c5nudfmtkle6bnexrkeqo6dtivnukaf6slxu",
    },
    {
      name: "INTERSTELLAR VISIONARY",
      value: 50000,
      link: "https://nftstorage.link/ipfs/bafkreiguyfoddc5hx3nmgovzlkpnw6liju3eehilhjwbsatsbqiiww4i6e",
    },
    {
      name: "VOYAGER'S AURA",
      value: 50,
      link: "https://bafkreifkycz5gzzycxfsn3byano5bbxtbnd6b224773e5mw27xn3hsksly.ipfs.nftstorage.link/",
    },
  ],

  NFT_CONTRACTS: [
    "0x01d8a1BdC9D1FCc5826Cd2abEf972614Cf6a75f8",
    "0x86193c3E699FdAf8072186e88b5094DEdF4338d4",
    "0x04d753A7662d5326EE585EE6656fe91b6Ce417c3",
    "0xe0E7D828424db320d883C705239bd19Ca34056c5",
    "0x97dE69c414483325419E63c3028AcB712708FBbD",
    "0xf6237168aC5bf98cDd8Db8Ca40Bf54fDF6333EB4",
    "0x287bb75227e78C24b5514D627F6370a0192c63BE",
    "0xb5399617c32Fdbd30446aD13D3A4d36bf8d3F1D5",

    "0x7D1629cd37a892A7C09376CE147749aA8c562Cb0",
  ],

  // Testnet...

  // VITINIXX_ORACLE: "0x8e692A9De45F9DaFEeE4453fc2E41A9315F2c0B7",
  // VTC: "0x998b63d830dd8105130c0c2343C1cB6DDa1f6b26",
  // CZX: "0x94F36f3d33F2892A004C956646652997EfC04DcF",
  // USDT: "0x69F13101310c8e646f50E01a0F7Fd97974E8A5CA",

  // Mainnet...
  VITINIXX_ORACLE: "0x8E433Cc64aA9C1F90bE427Abead03745011cBf97",
  CZX: "0x7ad95eb6064bf69870a9dd4dafdff941bf60ae88",
  VTC: "0x7d24903B4c4c81456cf9Bf66b73CE95Dc6914950",
  USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  ORACLE_ADDRESS: "0x6d3CE50Dd737278c3e7E8cB3c3A623e3DF6d966c",

  AllowanceAmount: 1000000000000,
  BuyTokenAmount: 10000000,

  PairStopper: "100",

  ORACLE_ABI: [
    "function getPrice(address czx,address vtc,address usdt,bool isCZX) external view returns (uint256)",
  ],

  // Mainnet...

  NETWORK_ID: 137,
  NETWORK_HEXA_ID: "0x89",

  // Testnet...

  // NETWORK_ID: 80001,
  // NETWORK_HEXA_ID: "0x13881",

  pairs: [
    {
      tokenA: "USDT",
      tokenB: "CZX",
    },
    {
      tokenA: "VTC",
      tokenB: "CZX",
    },
  ],
};

export default NFTFiles;
