import React,{useState,useEffect} from 'react'
import { getData } from "../api/thegraph";
import NFTFiles from '../Assets/data';
import { useGlobalContext } from '../context/walletContext';
import {ethers} from 'ethers'


const History = () => {
  const { address } = useGlobalContext();
  const [graphData, setGraphData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const totalPages = Math.ceil(graphData.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentRows = graphData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const getGraphData = async () => {
    const response = await getData(address);
    const graphArr = response.data.userDatas;

    let temp = [];

    graphArr.forEach((item) => {
      let nftNo = item.nftID - 1;
      let dateStr = DateTimeConverter(item.blockTimestamp);

      let vtc = ethers.utils.formatUnits(item.VTCDollors, 18);
      let czx = ethers.utils.formatUnits(item.CZXDollors, 18);

      let obj = {
        nftName: NFTFiles.nft[nftNo].name,
        tx: `https://polygonscan.com/tx/${item.transactionHash}`,
        nftPrice: NFTFiles.nft[nftNo].value,
        vtcPrice: vtc,
        czxPrice: czx,
        date: dateStr,
        nftNum: nftNo,
      };

      temp.push(obj);
    });
    setGraphData(temp);
  };

  const DateTimeConverter = (timestamp) => {
    let date = new Date(timestamp * 1000);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (address) {
      getGraphData();
      const interval = setInterval(() => {
        getGraphData();
      }, 7000); // Run every 10 seconds
      return () => clearInterval(interval);
    }
  }, [address]); 

  return (
    <>
      <section className="historysec">
        <div className="container">
          <h2 className="history-headline mt-2">
            <span>history</span>
          </h2>
          <br />
          <div className=" w-80 h-auto table-wrap table-container ">
            <table className="w-100 text-center ">
              <thead className="w-100 overflow-auto ">
                <tr className="   ">
                  <th className="py-1 w-40 px-0 px-md-0  py-md-4 text-left">
                    SR No.
                  </th>
                  <th className="py-1 w-80 px-0  py-md-4">NFT Name</th>
                  <th className="py-1 w-80 px-0  py-md-4">Tx </th>
                  <th className="py-1 w-80 px-0  py-md-4">NFT Price</th>
                  <th className="py-1 w-80 px-0  py-md-4">
                    VTC Price
                    <span className="block -mt-1 leading-[1]">
                      <small>(At Purchase Time)</small>
                    </span>
                  </th>
                  <th className="py-1 px-0  py-md-4">
                    CUSD Price
                    <span className="block -mt-1 leading-[1]">
                      <small>(At Purchase Time)</small>
                    </span>
                  </th>
                  <th
                    className="py-2 px-0 
                   py-md-4"
                  >
                    Date
                  </th>
                </tr>
              </thead>

              <tbody className="w-80 text-base">
                {currentRows.length > 0 ? (
                  currentRows.map((item, index) => (
                    <tr key={index} className=" ">
                      <td className="py-2 px-0 px-lg-5 py-md-4">
                        {(currentPage - 1) * rowsPerPage + index + 1}
                      </td>
                      <td className="py-2 px-0  py-md-4">{item.nftName}</td>
                      <td className="py-2 px-0  py-md-4">
                        <a
                          href={item.tx}
                          style={{ color: "white" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <u>View Tx</u>
                        </a>
                      </td>
                      <td className="py-2 px-0  py-md-4">{item.nftPrice}</td>
                      <td className="py-2 px-0  py-md-4">{item.vtcPrice}</td>
                      <td className="py-2 px-0  py-md-4">{item.czxPrice}</td>
                      <td className="py-2 px-0  py-md-4">{item.date}</td>
                      <td className="py-2 px-0  py-md-4"></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Pagination */}
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 && "disabled"}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    className={`page-item ${
                      index + 1 === currentPage && "active"
                    }`}
                    key={index}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages && "disabled"
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
}

export default History