import React,{useState,useEffect} from 'react'
import logo from '../Assets/Images/white 3d.png'
import { useGlobalContext } from "../context/walletContext";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";

const Header = () => {

  const {
    connectWallet,
    setAddress,
    address,
    checkNetwork,
    network,
    requestNetworkShift,
  } = useGlobalContext();

  const handleConnectWallet = async () => {
    await connectWallet();
  };

  const [isNetworkWarn, setIsNetworkWarn] = useState(null);

  const handleDisconnectWallet = async () => {
    setAddress(null);
    toast.success("Wallet Disconnected");
  };

  const handleCopyAddress = () => {
    copy(address);
    toast.success("Address Copied");
  };


  useEffect(() => {
    if(address && network){ 
     if (!checkNetwork()) setIsNetworkWarn(true);
     else setIsNetworkWarn(false);     
    }
  }, [network,address,connectWallet]);


  return (
    <>
      <header>
        <div className="container">
          <nav>
            <div className="nav-brand">
              <a href="#home">
                <img src={logo} className="  " alt="" />
              </a>
            </div>
            <div className="nav-btn-area">
              {!address ? (
                <a href="#" className="btn-main">
                  <span onClick={handleConnectWallet}>Connect</span>
                </a>
              ) : (
                <>
                  {isNetworkWarn ? (
                    <button
                      onClick={() => requestNetworkShift()}
                      style={{ marginRight: "10px", height: "45px" }}
                      type="button"
                      class="btn btn-danger"
                    >
                      Wrong Network
                    </button>
                  ) : null}
                  <a href="#" className="btn-main">
                    <span onClick={handleCopyAddress}>
                      {address.slice(0, 5)}...{address.slice(-4)}
                    </span>
                  </a>
                  <a
                    href="#"
                    className="btn-main"
                    style={{ marginLeft: "15px" }}
                  >
                    <span onClick={handleDisconnectWallet}>Disconnect</span>
                  </a>
                </>
              )}
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;