import React from 'react'
import Banner from '../Components/Banner'
import Nft from '../Components/Nft'
import History from '../Components/History'
import Nft_History from '../Components/Nft_History'

const Home = () => {
  return (
    <div>
        <Banner />
        {/* <Nft /> */}
        {/* <History /> */}
        <Nft_History />
    </div>
  )
}

export default Home