import React, { useEffect, useRef } from 'react';
import { Carousel } from 'bootstrap';


import 'bootstrap/dist/css/bootstrap.min.css';

import Nft from './Nft';

import History from './History';


function MergedCarousel() {
    const carouselRef = useRef(null);

    useEffect(() => {
        const carouselInstance = new Carousel(carouselRef.current);

        // Cleanup: destroy the carousel instance when the component is unmounted
        return () => carouselInstance.dispose();
    }, []);






    return (
        <div ref={carouselRef} id="carouselExampleIndicators" className="carousel slide nfthis " data-bs-interval="false" >
            <div className="carousel-indicators mb-4 ">
             
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" >    <span>Nfts</span></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"><span>History</span></button>
            </div>

            <div className="carousel-inner">
                <div className="carousel-item active">
                <Nft /> 

                </div>
                <div className="carousel-item ">
                   
                    <History />
                </div>
            </div>

        </div>
    );
}

export default MergedCarousel;
